import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Assessment,
  Category,
  ProcuredItem,
  ReportTotals,
  Supplier,
} from "../../data-store";
import useCategories from "../../data-store/useCategories";
import useExportUrl from "../../data-store/useExportUrl";
import useFoodTypes from "../../data-store/useFoodstepsCategories";
import useProcuredItems from "../../data-store/useProcuredItems";
import useReportTotals from "../../data-store/useReportTotals";
import useSuppliers from "../../data-store/useSuppliers";
import { ImpactCategory } from "../../domain/impactCategories";
import { useTracking } from "../../tracking";
import Page from "../Page";
import { Scope3PageQueryParams } from "../pages";
import StatusDisplay from "../StatusDisplay";
import { BreadcrumbPage } from "../utils/Breadcrumb";
import { PrimaryButton } from "../utils/Button";
import Select from "../utils/Select";
import { Download } from "../utils/Vectors";
import { impactMagnitudeAndProportionChange } from "./helperFunctions";
import ReportContent from "./ReportContent";
import "./ReportPage.css";
import "../Page.css";

interface ReportPageProps {
  assessments: Array<Assessment>;
  breadcrumb: BreadcrumbPage[];
  defaultAssessment: Assessment;
  impactCategory: ImpactCategory;
  queryParams: Scope3PageQueryParams;
  setQueryParams: (update: Partial<Scope3PageQueryParams>) => void;
  title: string;
}

export default function ReportPage(props: ReportPageProps) {
  const {
    assessments,
    breadcrumb,
    defaultAssessment,
    impactCategory,
    queryParams,
    setQueryParams,
    title,
  } = props;

  const tracking = {
    pageName: title,
    pageAttributes: {
      defaultAssessmentId: defaultAssessment.id,
      defaultAssessmentName: defaultAssessment.name,
    },
  };

  return (
    <Page contentHasPadding={false} tracking={tracking}>
      {/*Content in its own component to avoid re-rendering the page tracking from all the queries*/}
      <ReportPageContent
        assessments={assessments}
        breadcrumb={breadcrumb}
        impactCategory={impactCategory}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        title={title}
      />
    </Page>
  );
}

interface ReportPageContentProps {
  assessments: Array<Assessment>;
  breadcrumb: BreadcrumbPage[];
  impactCategory: ImpactCategory;
  queryParams: Scope3PageQueryParams;
  setQueryParams: (update: Partial<Scope3PageQueryParams>) => void;
  title: string;
}

function ReportPageContent(props: ReportPageContentProps) {
  const {
    assessments,
    breadcrumb,
    impactCategory,
    queryParams,
    setQueryParams,
    title,
  } = props;

  const { trackAssessmentSelected, trackProcurementImpactsExported } =
    useTracking();

  const chosenAssessment = assessments.filter(
    (assessment) => assessment.id === queryParams.assessmentId
  )[0];
  const assessmentId = queryParams.assessmentId;
  const [reportTotalsStatus] = useReportTotals(assessmentId);
  const [categoriesStatus] = useCategories(assessmentId);
  const [exportUrlStatus] = useExportUrl(assessmentId);
  const [itemsStatus] = useProcuredItems(assessmentId);
  const [foodTypesStatus] = useFoodTypes(assessmentId);
  const [suppliersStatus] = useSuppliers(assessmentId);

  return (
    <>
      <div className="StickyPageTitle ReportPage_Header">
        <Page.Title breadcrumb={breadcrumb} title={title} />
        <div className="ReportPage_Header_Controls">
          <Select<Assessment>
            className="ReportPage_AssessmentSelect"
            isClearable={false}
            onChange={(reportName) => {
              if (reportName !== null) {
                trackAssessmentSelected({
                  assessmentId: reportName.id,
                  assessmentName: reportName.name,
                  reportType: impactCategory,
                });
              }
              setQueryParams({ assessmentId: reportName?.id });
            }}
            optionKey={(reportName) => reportName.id}
            options={assessments}
            renderOption={(reportName) => reportName.name}
            dropdownArrow="upDown"
            value={chosenAssessment}
          />
          <StatusDisplay status={exportUrlStatus}>
            {(exportInfo) =>
              exportInfo !== null && (
                <a
                  href={`${
                    (window as any).FOODSTEPS_PLATFORM_URI
                  }${exportInfo.downloadLink.substring(1)}`} // substring(1) to strip the extra '/'
                  target="_blank"
                  rel="noreferrer"
                >
                  <PrimaryButton
                    icon={<Download width={16} />}
                    onClick={() =>
                      trackProcurementImpactsExported({
                        assessmentId,
                        numItems: Number(exportInfo.numItems),
                      })
                    }
                  >
                    <FormattedMessage
                      id="components/scope-3/ReportPage:export"
                      defaultMessage="Export"
                    />
                  </PrimaryButton>
                </a>
              )
            }
          </StatusDisplay>
        </div>
      </div>
      <div className="ReportPage_ContentContainer">
        <StatusDisplay.Many<
          [
            Array<Category> | null,
            Array<Category> | null,
            Array<ProcuredItem> | null,
            Array<Supplier> | null,
            ReportTotals | null
          ]
        >
          statuses={[
            categoriesStatus,
            foodTypesStatus,
            itemsStatus,
            suppliersStatus,
            reportTotalsStatus,
          ]}
        >
          {(categories, foodTypes, procuredItems, suppliers, totals) =>
            categories !== null &&
            foodTypes !== null &&
            procuredItems !== null &&
            suppliers !== null &&
            totals !== null ? (
              <ReportContent
                categories={categories}
                foodstepsCategories={foodTypes}
                impactCategory={impactCategory}
                procuredItems={procuredItems}
                suppliers={suppliers}
                impactMagnitudeAndProportionChange={impactMagnitudeAndProportionChange(
                  totals,
                  impactCategory
                )}
                totalSpend={
                  // I assume we send this as a string because it's a decimal
                  // but we need it as a number in order to format it properly
                  totals.spend != null ? Number(totals.spend.value) : null
                }
              />
            ) : (
              <FormattedMessage
                id="components/scope-3/ReportPage:noDataFound"
                defaultMessage="No report data found."
              />
            )
          }
        </StatusDisplay.Many>
      </div>
    </>
  );
}
