import { isEqual } from "lodash";
import { IntlShape } from "react-intl";

import assertNever from "./assertNever";

const massUnits = ["g", "kg", "T", "MT", "GT"] as const;
const landUnits = ["m2", "km2"] as const;
const waterUnits = ["L", "kL", "ML"] as const;
const otherUnits = ["co2e", "year"] as const;
const perOptions = [
  "kg",
  "T",
  "serving",
  "product",
  "EUR",
  "GBP",
  "USD",
] as const;

export type MassUnit = (typeof massUnits)[number];
export type LandUnit = (typeof landUnits)[number];
export type WaterUnit = (typeof waterUnits)[number];
export type OtherUnit = (typeof otherUnits)[number];
export type PerOption = (typeof perOptions)[number];

const isMassUnit = (unit: string): unit is MassUnit =>
  massUnits.includes(unit as MassUnit);
const isLandUnit = (unit: string): unit is LandUnit =>
  landUnits.includes(unit as LandUnit);
const isWaterUnit = (unit: string): unit is WaterUnit =>
  waterUnits.includes(unit as WaterUnit);

export type ConvertableUnit = MassUnit | LandUnit | WaterUnit;
export type Unit = ConvertableUnit | OtherUnit;

export type UnitType = "mass" | "land" | "water";

const convertToKG = (value: number, unit: MassUnit) => {
  const conversions = {
    g: value / 1_000,
    kg: value,
    T: value * 1_000,
    MT: value * 1_000_000,
    GT: value * 1_000_000_000,
  };
  return conversions[unit];
};

const convertToM2 = (value: number, unit: LandUnit) => {
  const conversions = {
    m2: value,
    km2: value * 1_000_000,
  };
  return conversions[unit];
};

const convertToL = (value: number, unit: WaterUnit) => {
  const conversions = {
    L: value,
    kL: value * 1_000,
    ML: value * 1_000_000,
  };
  return conversions[unit];
};

type ConvertedValueAndUnit = {
  convertedValue: number;
  unit: ConvertableUnit;
};

// Determine appropriate unit based on value
const determineAppropriateUnit = (
  baseValue: number,
  unit: ConvertableUnit
): ConvertedValueAndUnit => {
  if (isMassUnit(unit)) {
    // Disabling automatic use of MT and GT for now
    // if (baseValue >= 1_000_000_000)
    //   return { convertedValue: baseValue / 1_000_000_000, unit: "GT" };
    // if (baseValue >= 1_000_000)
    //   return { convertedValue: baseValue / 1_000_000, unit: "MT" };
    if (baseValue >= 1_000)
      return { convertedValue: baseValue / 1_000, unit: "T" };
    if (baseValue < 1) return { convertedValue: baseValue * 1_000, unit: "g" };
    return { convertedValue: baseValue, unit: "kg" };
  } else if (isLandUnit(unit)) {
    if (baseValue >= 1_000_000)
      return { convertedValue: baseValue / 1_000_000, unit: "km2" };
    return { convertedValue: baseValue, unit: "m2" };
  } else if (isWaterUnit(unit)) {
    if (baseValue >= 1_000_000)
      return { convertedValue: baseValue / 1_000_000, unit: "ML" };
    if (baseValue >= 1_000)
      return { convertedValue: baseValue / 1_000, unit: "kL" };
    return { convertedValue: baseValue, unit: "L" };
  }
  assertNever(unit, `Invalid unit: ${unit}`);
};

export const convertUnits = (
  value: number,
  unitToConvert: ConvertableUnit,
  otherBaseUnits: Unit[] = [],
  internalPer: PerOption | null = null
) => {
  let converted: ConvertedValueAndUnit;
  if (isMassUnit(unitToConvert)) {
    const kgValue = convertToKG(value, unitToConvert);
    converted = determineAppropriateUnit(kgValue, "kg");
  } else if (isLandUnit(unitToConvert)) {
    const m2Value = convertToM2(value, unitToConvert);
    converted = determineAppropriateUnit(m2Value, "m2");
  } else if (isWaterUnit(unitToConvert)) {
    const lValue = convertToL(value, unitToConvert);
    converted = determineAppropriateUnit(lValue, "L");
  } else {
    assertNever(
      unitToConvert,
      `Invalid unit: ${unitToConvert}. Other base units: ${otherBaseUnits}, per: ${internalPer}`
    );
  }
  return {
    convertedValue: converted.convertedValue,
    baseUnits: [converted.unit, ...otherBaseUnits],
  };
};

export const convertUnitsForDisplay = (
  intl: IntlShape,
  value: number,
  unitToConvert: ConvertableUnit,
  otherBaseUnits: Unit[] = [],
  internalPer: PerOption | null = null,
  decimals: number = 2
) => {
  // note that some places use this code and some use the unit display code from frontend/src/domain/EffectType.tsx
  const converted = convertUnits(
    value,
    unitToConvert,
    otherBaseUnits,
    internalPer
  );
  return {
    convertedValue: intl.formatNumber(converted.convertedValue, {
      maximumFractionDigits: decimals,
    }),
    unitString: complexUnitToIntlString(intl, converted.baseUnits, internalPer),
  };
};

export const complexUnitToIntlString = (
  intl: IntlShape,
  baseUnits: Unit[],
  per: string | null = null
) => {
  if (per === null) {
    if (isEqual(baseUnits, ["kg"])) {
      return intl.formatMessage({
        id: "util/units:kg",
        defaultMessage: `kg`,
      });
    }
    if (isEqual(baseUnits, ["T"])) {
      return intl.formatMessage({
        id: "util/units:T",
        defaultMessage: `tonnes`,
      });
    }
    if (isEqual(baseUnits, ["MT"])) {
      return intl.formatMessage({
        id: "util/units:MT",
        defaultMessage: `MT`,
      });
    }
    if (isEqual(baseUnits, ["GT"])) {
      return intl.formatMessage({
        id: "util/units:GT",
        defaultMessage: `GT`,
      });
    }
    if (isEqual(baseUnits, ["m2"])) {
      return intl.formatMessage({
        id: "util/units:m2",
        defaultMessage: `m²`,
      });
    }
    if (isEqual(baseUnits, ["km2"])) {
      return intl.formatMessage({
        id: "util/units:km2",
        defaultMessage: `km²`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:L",
        defaultMessage: "L",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kL",
        defaultMessage: "kL",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:ML",
        defaultMessage: "ML",
      });
    }
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2e",
        defaultMessage: `kg CO₂e`,
      });
    }
    if (isEqual(baseUnits, ["T", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Tco2e",
        defaultMessage: `tonnes CO₂e`,
      });
    }
    if (isEqual(baseUnits, ["MT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:MTco2e",
        defaultMessage: `MT CO₂e`,
      });
    }
    if (isEqual(baseUnits, ["GT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:GTco2e",
        defaultMessage: `GT CO₂e`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2year",
        defaultMessage: `m²year`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2year",
        defaultMessage: `km²year`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:L",
        defaultMessage: "L",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kL",
        defaultMessage: "kL",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:ML",
        defaultMessage: "ML",
      });
    }
  }
  if (per === "kg") {
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2eperkg",
        defaultMessage: `kg CO₂e / kg`,
      });
    }
    if (isEqual(baseUnits, ["T", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Tco2eperkg",
        defaultMessage: `tonnes CO₂e / kg`,
      });
    }
    if (isEqual(baseUnits, ["MT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:MTco2eperkg",
        defaultMessage: `MT CO₂e / kg`,
      });
    }
    if (isEqual(baseUnits, ["GT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:GTco2eperkg",
        defaultMessage: `GT CO₂e / kg`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2yearperkg",
        defaultMessage: `m²year / kg`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2yearperkg",
        defaultMessage: `km²year / kg`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:Lperkg",
        defaultMessage: "L / kg",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kLperkg",
        defaultMessage: "kL / kg",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:MLperkg",
        defaultMessage: "ML / kg",
      });
    }
  }
  if (per === "T") {
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2eperT",
        defaultMessage: `kg CO₂e / tonne`,
      });
    }
    if (isEqual(baseUnits, ["T", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Tco2eperT",
        defaultMessage: `tonnes CO₂e / tonne`,
      });
    }
    if (isEqual(baseUnits, ["MT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:MTco2eperT",
        defaultMessage: `MT CO₂e / tonne`,
      });
    }
    if (isEqual(baseUnits, ["GT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:GTco2eperT",
        defaultMessage: `GT CO₂e / tonne`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2yearpert",
        defaultMessage: `m²year / tonne`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2yearpert",
        defaultMessage: `km²year / tonne`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:LperT",
        defaultMessage: "L / tonne",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kLperT",
        defaultMessage: "kL / tonne",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:MLperT",
        defaultMessage: "ML / tonne",
      });
    }
  }
  if (per === "serving") {
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2esperserving",
        defaultMessage: `kg CO₂e per serving`,
      });
    }
    if (isEqual(baseUnits, ["T", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Tco2esperserving",
        defaultMessage: `tonnes CO₂e per serving`,
      });
    }
    if (isEqual(baseUnits, ["MT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:MTco2esperserving",
        defaultMessage: `MT CO₂e per serving`,
      });
    }
    if (isEqual(baseUnits, ["GT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:GTco2esperserving",
        defaultMessage: `GT CO₂e per serving`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2yearperserving",
        defaultMessage: `m²year per serving`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2yearperserving",
        defaultMessage: `km²year per serving`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:Lperserving",
        defaultMessage: "L per serving",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kLperserving",
        defaultMessage: "kL per serving",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:MLperserving",
        defaultMessage: "ML per serving",
      });
    }
  }
  if (per === "product") {
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2eperproduct",
        defaultMessage: `kg CO₂e per product`,
      });
    }
    if (isEqual(baseUnits, ["T", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Tco2eperproduct",
        defaultMessage: `tonnes CO₂e per product`,
      });
    }
    if (isEqual(baseUnits, ["MT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:MTco2eperproduct",
        defaultMessage: `MT CO₂e per product`,
      });
    }
    if (isEqual(baseUnits, ["GT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:GTco2eperproduct",
        defaultMessage: `GT CO₂e per product`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2yearperproduct",
        defaultMessage: `m²year per product`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2yearperproduct",
        defaultMessage: `km²year per product`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:Lperproduct",
        defaultMessage: "L per product",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kLperproduct",
        defaultMessage: "kL per product",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:MLperproduct",
        defaultMessage: "ML per product",
      });
    }
  }
  if (per === "EUR") {
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2e€",
        defaultMessage: `kg CO₂e / €`,
      });
    }
    if (isEqual(baseUnits, ["T", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Tco2e€",
        defaultMessage: `tonnes CO₂e / €`,
      });
    }
    if (isEqual(baseUnits, ["MT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:MTco2e€",
        defaultMessage: `MT CO₂e / €`,
      });
    }
    if (isEqual(baseUnits, ["GT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:GTco2e€",
        defaultMessage: `GT CO₂e / €`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2year€",
        defaultMessage: `m²year / €`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2year€",
        defaultMessage: `km²year / €`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:L€",
        defaultMessage: "L / €",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kL€",
        defaultMessage: "kL / €",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:ML€",
        defaultMessage: "ML / €",
      });
    }
  }

  if (per === "GBP") {
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2e£",
        defaultMessage: `kg CO₂e / £`,
      });
    }
    if (isEqual(baseUnits, ["T", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Tco2e£",
        defaultMessage: `tonnes CO₂e / £`,
      });
    }
    if (isEqual(baseUnits, ["MT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:MTco2e£",
        defaultMessage: `MT CO₂e / £`,
      });
    }
    if (isEqual(baseUnits, ["GT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:GTco2e£",
        defaultMessage: `GT CO₂e / £`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2year£",
        defaultMessage: `m²year / £`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2year£",
        defaultMessage: `km²year / £`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:L£",
        defaultMessage: "L / £",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kL£",
        defaultMessage: "kL / £",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:ML£",
        defaultMessage: "ML / £",
      });
    }
  }

  if (per === "USD") {
    if (isEqual(baseUnits, ["kg", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:kgco2e$",
        defaultMessage: `kg CO₂e / $`,
      });
    }
    if (isEqual(baseUnits, ["T", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:Tco2e$",
        defaultMessage: `tonnes CO₂e / $`,
      });
    }
    if (isEqual(baseUnits, ["MT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:MTco2e$",
        defaultMessage: `MT CO₂e / $`,
      });
    }
    if (isEqual(baseUnits, ["GT", "co2e"])) {
      return intl.formatMessage({
        id: "util/units:GTco2e$",
        defaultMessage: `GT CO₂e / $`,
      });
    }
    if (isEqual(baseUnits, ["m2", "year"])) {
      return intl.formatMessage({
        id: "util/units:m2year$",
        defaultMessage: `m²year / $`,
      });
    }
    if (isEqual(baseUnits, ["km2", "year"])) {
      return intl.formatMessage({
        id: "util/units:km2year$",
        defaultMessage: `km²year / $`,
      });
    }
    if (isEqual(baseUnits, ["L"])) {
      return intl.formatMessage({
        id: "util/units:L$",
        defaultMessage: "L / $",
      });
    }
    if (isEqual(baseUnits, ["kL"])) {
      return intl.formatMessage({
        id: "util/units:kL$",
        defaultMessage: "kL / $",
      });
    }
    if (isEqual(baseUnits, ["ML"])) {
      return intl.formatMessage({
        id: "util/units:ML$",
        defaultMessage: "ML / $",
      });
    }
  }

  throw new Error(`Invalid unit combination: ${baseUnits}, ${per}`);
};
