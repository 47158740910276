import sum from "lodash/sum";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Category,
  ProcuredItem,
  Supplier,
  ValueWithProportionalChange,
} from "../../data-store";
import { impactCategoryToEffectType } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import { useTracking } from "../../tracking";
import assertNever from "../../util/assertNever";
import { convertUnitsForDisplay } from "../../util/units";
import { impactCategoryIcon } from "../corporate-reporting-dashboards/AssessmentOverviewSummary";
import { usePages } from "../pages";
import { Panel } from "../utils/Panel";
import { Apple, Spend, TotalMass } from "../utils/Vectors/illustrations";
import CategoriesTable from "./CategoriesTable";
import ProcuredItemsTable from "./ProcuredItemsTable";
import "./ReportContent.css";
import ReportSummaryCard from "./ReportSummaryCard";
import SuppliersTable from "./SuppliersTable";
import TableOptionToggle, { TableOption, Option } from "./TableOptionToggle";

interface ReportContentProps {
  categories: Array<Category>;
  foodstepsCategories: Array<Category>;
  impactCategory: ImpactCategory;
  procuredItems: Array<ProcuredItem>;
  impactMagnitudeAndProportionChange: ValueWithProportionalChange<
    number | null
  >;
  totalSpend: number | null;
  suppliers: Array<Supplier>;
}

export default function ReportContent(props: ReportContentProps) {
  const {
    categories,
    foodstepsCategories,
    impactCategory,
    procuredItems,
    suppliers,
    impactMagnitudeAndProportionChange,
    totalSpend,
  } = props;

  const { trackReportBreakdownSet } = useTracking();

  const effectType = impactCategoryToEffectType(impactCategory);
  const intl = useIntl();
  const pages = usePages();
  const [queryParams] = pages.Scope3Page.useQueryParams();

  const [tableOption, setTableOption] = useState<TableOption>(
    queryParams.viewHighestImpactItems ? Option.PROCURED_ITEM : Option.CATEGORY
  );

  const table = () => {
    if (tableOption === Option.CATEGORY) {
      return (
        <CategoriesTable
          data={categories}
          impactCategory={impactCategory}
          categoryColumnLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:category",
            defaultMessage: "Category",
          })}
          unspecifiedCategoryLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:unspecifiedCategory",
            defaultMessage: "Unspecified category",
          })}
        />
      );
    } else if (tableOption === Option.FOOD_TYPE) {
      return (
        <CategoriesTable
          data={foodstepsCategories}
          impactCategory={impactCategory}
          categoryColumnLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:foodType",
            defaultMessage: "Food Type",
          })}
          unspecifiedCategoryLabel={intl.formatMessage({
            id: "components/scope-3/ReportContent:unspecifiedFoodType",
            defaultMessage: "Unspecified food type",
          })}
        />
      );
    } else if (tableOption === Option.PROCURED_ITEM) {
      return (
        <ProcuredItemsTable
          data={procuredItems}
          impactCategory={impactCategory}
        />
      );
    } else if (tableOption === Option.SUPPLIER) {
      return (
        <SuppliersTable data={suppliers} impactCategory={impactCategory} />
      );
    } else {
      assertNever(tableOption, "Unsupported table type");
    }
  };

  const handleTableTypeChange = (selectedTableType: TableOption | null) => {
    if (selectedTableType === null) {
      throw new Error("Cannot have null table type");
    }
    trackReportBreakdownSet({
      breakdown: selectedTableType,
      reportType: impactCategory,
    });
    setTableOption(selectedTableType);
  };

  const impactInAppropriateUnits =
    impactMagnitudeAndProportionChange.value === null
      ? null
      : convertUnitsForDisplay(
          intl,
          impactMagnitudeAndProportionChange.value,
          effectType.internalBaseUnits.unitToConvert,
          effectType.internalBaseUnits.otherBaseUnits
        );
  const totalMassInAppropriateUnits = convertUnitsForDisplay(
    intl,
    sum(procuredItems.map((item) => item.massKg)),
    "kg"
  );

  return (
    <div className="ReportContent">
      <Panel className="ReportContent_Panel">
        <h3>
          <FormattedMessage
            id="components/scope-3/ReportContent:summary"
            defaultMessage="Summary"
          />
        </h3>
        <div className="ReportContent_SummaryCards">
          {impactInAppropriateUnits !== null && (
            <ReportSummaryCard
              icon={impactCategoryIcon(impactCategory)}
              title={
                <>
                  <FormattedMessage
                    id="components/scope-3/ReportContent:total"
                    defaultMessage="Total Impact"
                  />{" "}
                  {effectType.title(intl)}
                </>
              }
              value={impactInAppropriateUnits.convertedValue}
              unit={impactInAppropriateUnits.unitString}
            />
          )}
          <ReportSummaryCard
            icon={<Apple width={32} />}
            title={
              <FormattedMessage
                id="components/scope-3/ReportContent:procuredItems"
                defaultMessage="Procured Items"
              />
            }
            value={intl.formatNumber(procuredItems.length)}
          />
          <ReportSummaryCard
            icon={<TotalMass width={32} />}
            title={
              <FormattedMessage
                id="components/scope-3/ReportContent:totalMass"
                defaultMessage="Total Mass"
              />
            }
            value={totalMassInAppropriateUnits.convertedValue}
            unit={totalMassInAppropriateUnits.unitString}
          />
          <ReportSummaryCard
            icon={<Spend width={32} />}
            title={
              <FormattedMessage
                id="components/scope-3/ReportContent:totalSpend"
                defaultMessage="Total Spend"
              />
            }
            value={totalSpend !== null ? intl.formatNumber(totalSpend) : "-"}
            unit={
              totalSpend ? (
                <FormattedMessage
                  id="components/scope-3/ReportContent:£"
                  defaultMessage="£"
                />
              ) : null
            }
          />
        </div>
      </Panel>
      <div>
        <Panel>
          <div className="ReportContent_TableTypeSelect_Container">
            <h3 className="medium-font ReportContent_BreakdownBy">
              <FormattedMessage
                id="components/scope-3/ReportContent:breakdownBy"
                defaultMessage="Breakdown by"
              />
            </h3>
            <TableOptionToggle
              onChange={handleTableTypeChange}
              option={tableOption}
            />
          </div>
          {table()}
        </Panel>
      </div>
    </div>
  );
}
