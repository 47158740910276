import React, { useContext } from "react";

import { CalculationTableRow } from "../components/calculation-table/CalculationTable";
import FetchGraphQL from "../components/graphql/FetchGraphQL";
import { ProportionsWithProductCount } from "../components/scope-3/ImpactRatingProportionsChart";
import AvailableIngredient from "../domain/AvailableIngredient";
import { ImpactRating } from "../domain/impactRatings";

export interface ChangePasswordOptions {
  userId: number;
  username: string;
  oldPassword: string;
  newPassword: string;
}

export interface MaintenanceWindow {
  start: Date;
  end: Date;
  isActive: boolean;
}

export interface Category {
  name: string;
  spend: string | null;
  landUse: number;
  massKg: number;
  ghg: number;
  waterUse: number;
}

export interface ProcuredItem {
  categoryName: string | null;
  ghg: number | null;
  impactRatingGhg: ImpactRating | null;
  impactRatingLandUse: ImpactRating | null;
  impactRatingWaterUse: ImpactRating | null;
  internalId: string;
  landUse: number | null;
  name: string;
  massKg: number | null;
  quantity: number | null;
  spend: string | null;
  unit: string | null;
  waterUse: number | null;
}

export type ValueWithProportionalChange<T> = {
  value: T;
  proportionalChange?: number | null;
};

export interface ReportTotals {
  ghg: ValueWithProportionalChange<number>;
  waterUse: ValueWithProportionalChange<number>;
  landUse: ValueWithProportionalChange<number>;
  spend: ValueWithProportionalChange<string | null>;
}

export interface AssessmentTotals {
  assessmentId: string;
  ghg: number | null;
  waterUse: number | null;
  landUse: number | null;
  name: string;
}

export interface ReportExportInfo {
  downloadLink: string;
  numItems: string;
}

export interface ImpactRatingProportion {
  itemCount: number;
  valueWithProportionalChange: ValueWithProportionalChange<number | null>;
}

export interface ImpactRatingProportions {
  veryLow: ImpactRatingProportion | null;
  low: ImpactRatingProportion | null;
  medium: ImpactRatingProportion | null;
  high: ImpactRatingProportion | null;
  veryHigh: ImpactRatingProportion | null;
}
export interface AssessmentSummary extends ReportTotals {
  ghgPerSpend: ValueWithProportionalChange<number | null>;
  landUsePerSpend: ValueWithProportionalChange<number | null>;
  waterUsePerSpend: ValueWithProportionalChange<number | null>;
  spendCurrency: "EUR" | "GBP" | "USD" | null;
  ghgIntensity: ValueWithProportionalChange<number>;
  impactRatingGhg: ImpactRating | null;
  landUseIntensity: ValueWithProportionalChange<number>;
  impactRatingLandUse: ImpactRating | null;
  impactRatingWaterUse: ImpactRating | null;
  impactRatingProportions: {
    ghg: ImpactRatingProportions;
    landUse: ImpactRatingProportions;
    waterUse: ImpactRatingProportions;
  };
  waterUseIntensity: ValueWithProportionalChange<number>;
  comparisonAssessmentName: string | null;
}

export interface Assessment {
  id: string;
  name: string;
  created_at: string;
  period_covered_start: string;
  spend_currency: string;
  number_of_items_assessed: number;
  show_scope3_report: boolean;
  show_land_use_report: boolean;
  show_water_use_report: boolean;
  is_default: boolean;
}

export interface ProductsDashboardOrganization {
  id: string;
  name: string;
}

export interface ProductsDashboardData {
  averageIntensityGhg: number | null;
  averageIntensityImpactRatingGhg: ImpactRating | null;
  averageIntensityLandUse: number | null;
  averageIntensityImpactRatingLandUse: ImpactRating | null;
  averageIntensityWaterUse: number | null;
  averageIntensityImpactRatingWaterUse: ImpactRating | null;
  highestImpactProductGhg: DashboardProduct | null;
  lowestImpactProductGhg: DashboardProduct | null;
  highestImpactProductLandUse: DashboardProduct | null;
  lowestImpactProductLandUse: DashboardProduct | null;
  highestImpactProductWaterUse: DashboardProduct | null;
  lowestImpactProductWaterUse: DashboardProduct | null;
  numberOfDescendantOrganizations: number;
  numberOfProducts: number;
  tags: Array<DashboardProductTag>;
  dietaryCategories: Array<DashboardDietaryCategory>;
  impactRatingProportions: {
    ghg: ProportionsWithProductCount;
    landUse: ProportionsWithProductCount;
    waterUse: ProportionsWithProductCount;
  };
}

export interface DashboardProduct {
  id: number;
  name: string;
  impactCached: {
    ghgPerKg: number | null;
    impactRatingGhg: ImpactRating | null;
    landUsePerKg: number | null;
    impactRatingLandUse: ImpactRating | null;
    waterUsePerKg: number | null;
    impactRatingWaterUse: ImpactRating | null;
  };
}

export interface DashboardProductTag {
  name: string;
  count: number;
  averageIntensityGhg: number | null;
  impactRatingGhg: ImpactRating | null;
  averageIntensityLandUse: number | null;
  impactRatingLandUse: ImpactRating | null;
  averageIntensityWaterUse: number | null;
  impactRatingWaterUse: ImpactRating | null;
}

export interface DashboardDietaryCategory {
  name: string;
  productCount: number;
}

export interface Supplier {
  name: string;
  spend: string;
  landUse: number;
  massKg: number;
  ghg: number;
  waterUse: number;
}

export interface SupportedRegion {
  id: number;
  name: string;
}

export interface TagAverageImpactIntensity {
  tagName: string;
  ghg: number | null;
  landUse: number | null;
  waterUse: number | null;
}

export interface DataStore {
  fetchCalculationTable: (
    subjectId: string,
    subjectType: string,
    otherParams: URLSearchParams
  ) => Promise<CalculationTableRow>;

  fetchGraphQL: FetchGraphQL;

  fetchAvailableIngredients: (
    organizationId: string | null
  ) => Promise<Array<AvailableIngredient>>;

  fetchMaintenanceWindows: () => Promise<Array<MaintenanceWindow>>;

  fetchMethodologySummaryUrl: () => Promise<string | null>;

  fetchPublicOrganizationData: (
    organizationId: string
  ) => Promise<{ organizationName: string }>;

  changePassword: (args: ChangePasswordOptions) => Promise<void>;

  fetchProductsDashboardData: (
    organizationId: string,
    includeDescendants: boolean
  ) => Promise<ProductsDashboardData | null>;

  fetchProductsDashboardOrganizationOptions: (
    organizationId: string
  ) => Promise<Array<ProductsDashboardOrganization>>;

  fetchReportCategories: (
    assessmentId: string,
    organizationId: string
  ) => Promise<Array<Category> | null>;

  fetchReportExportUrl: (
    assessmentId: string,
    organizationId: string
  ) => Promise<ReportExportInfo | null>;

  fetchReportItems: (
    assessmentId: string,
    organizationId: string,
    pageSize?: number,
    sortColumn?: string,
    sortDirection?: "asc" | "desc"
  ) => Promise<Array<ProcuredItem> | null>;

  fetchReportTotals: (
    assessmentId: string,
    organizationId: string
  ) => Promise<ReportTotals | null>;

  fetchReportAssessmentList: (
    organizationId: string
  ) => Promise<Array<Assessment>>;

  fetchReportAssessmentSummary: (
    assessmentId: string,
    organizationId: string
  ) => Promise<AssessmentSummary | null>;

  fetchReportAssessmentTotals: (
    organizationId: string
  ) => Promise<Array<AssessmentTotals>>;

  fetchReportFoodTypes: (
    assessmentId: string,
    organizationId: string
  ) => Promise<Array<Category> | null>;

  fetchReportSuppliers: (
    assessmentId: string,
    organizationId: string
  ) => Promise<Array<Supplier>>;

  fetchAverageImpactIntensityByTag: (
    organizationId: string
  ) => Promise<Array<TagAverageImpactIntensity>>;

  fetchSupportedRegions: () => Promise<Array<SupportedRegion>>;

  key: unknown;
}

const DataStoreContext = React.createContext<DataStore | null>(null);

export function useDataStore(): DataStore {
  const dataStore = useContext(DataStoreContext);

  if (dataStore === null) {
    throw new Error("missing provider for DataStoreContext");
  } else {
    return dataStore;
  }
}

export const DataStoreProvider = DataStoreContext.Provider;
