import React from "react";

import { Assessment } from "../../data-store";
import useAssessments from "../../data-store/useAssessments";
import { ImpactCategory } from "../../domain/impactCategories";
import assertNever from "../../util/assertNever";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import { usePages } from "../pages";
import StatusDisplay from "../StatusDisplay";
import { BreadcrumbPage } from "../utils/Breadcrumb";
import ReportPage from "./ReportPage";

export function Scope3Page() {
  const pages = usePages();
  return (
    <BaseScope3Page
      breadcrumb={pages.Scope3.breadcrumb()}
      impactCategory={ImpactCategory.GHG}
      title={pages.Scope3.title}
    />
  );
}

interface BaseScope3PageProps {
  breadcrumb: BreadcrumbPage[];
  impactCategory: ImpactCategory;
  title: string;
}

export function BaseScope3Page(props: BaseScope3PageProps) {
  const { breadcrumb, impactCategory, title } = props;

  const pages = usePages();
  const [organizationId] = useOrganizationId();
  const [queryParams, setQueryParams] = pages.Scope3Page.useQueryParams();
  const [assessmentsStatus] = useAssessments(organizationId);
  return (
    <StatusDisplay status={assessmentsStatus}>
      {(assessments) => {
        const assessmentFilter = (assessment: Assessment) => {
          if (impactCategory === ImpactCategory.GHG) {
            return assessment.show_scope3_report;
          } else if (impactCategory === ImpactCategory.LAND_USE) {
            return assessment.show_land_use_report;
          } else if (impactCategory === ImpactCategory.WATER_USE) {
            return assessment.show_water_use_report;
          } else {
            assertNever(impactCategory, "invalid ImpactCategory");
          }
        };

        assessments = assessments
          .filter(assessmentFilter)
          .sort((a, b) =>
            a.period_covered_start < b.period_covered_start ? 1 : -1
          );
        const defaultAssessment = assessments[0];

        if (queryParams.assessmentId === "" && assessments.length > 0) {
          setQueryParams({ assessmentId: defaultAssessment.id });
        }
        if (queryParams.assessmentId !== "") {
          return (
            <ReportPage
              assessments={assessments}
              breadcrumb={breadcrumb}
              defaultAssessment={defaultAssessment}
              impactCategory={impactCategory}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              title={title}
            />
          );
        }
      }}
    </StatusDisplay>
  );
}
